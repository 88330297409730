import { ButtonDS, GlobalIcon, Typography, useDevice } from 'get-life-storybook-ts'
import { useContext, useEffect, useMemo } from 'react'
import { I18nContext } from '../../context/i18n.context'
import { getMessage } from '../StepsCalculatorPage/Components/PaymentGateway/components/PaymentCardContent/PaymentCardContent.component'
import "./Telesubscription.scss"
import { GetlifeContext } from '../../context/GetLifeContext.context'

const CALENDAR_MEET = "https://meetings-eu1.hubspot.com/rebeca-moron/es-meet-the-sales-team"

const Telesubscription = () => {
    const { state: { translate } } = useContext(I18nContext)
    const { setProgressBar } = useContext(GetlifeContext)
    const { isMobile } = useDevice()

    const disabledProgressBar = useMemo(() => {
        return false;
    }, []);

    useEffect(() => {
        setProgressBar(disabledProgressBar)
    }, [disabledProgressBar])

    return <div className='telesubscription'>
        <section className='telesubscription__section__first'>
            <GlobalIcon
                iconName="SearchIlustrationIcon"
                size={isMobile ? "IM" : "IL"}
            />
        </section>
        <section className='telesubscription__section__second'>
            <div className='telesubscription__section__second--text'>
                <Typography variant='H1' weight='700' text={translate("telesubscription.title")} color='purple' className='telesubscription__section__second--text--title' />
                <Typography variant='Body' text={translate("telesubscription.parragraph.first")} />
                <Typography variant='Body' text="">
                    {getMessage({ message: translate("telesubscription.parragraph.second"), replace: <b>{translate("telesubscription.parragraph.bold")}</b> })}
                </Typography>
            </div>

            <div className='telesubscription__section__second--buttons'>
                <ButtonDS
                    buttonType='primary'
                    leftIcon="CalendarEventLineIcon"
                    label={translate("telesubscription.button.calendar")}
                    onClick={() => window.open(CALENDAR_MEET)}
                    className='w-full'
                />
                <ButtonDS
                    buttonType='secondary'
                    leftIcon='PhoneIcon'
                    label={translate("telesubscription.button.phone").replace("$", `${process.env.REACT_APP_TEL_TELESUBSCRIPTION}`)}
                    onClick={() => window.location.href = `tel:${process.env.REACT_APP_TEL_TELESUBSCRIPTION}`}
                    className='w-full'
                />
            </div>
        </section>

    </div>
}

export default Telesubscription